import { useRef, useState } from "react";
import {
  Input,
  Button,
  Radio,
  List,
  message,
  Row,
  Col,
  Typography,
  Popover,
  Image,
  Space,
} from "antd";
import { FileImageOutlined, DownloadOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import AudioPlayer from "react-modern-audio-player";
import axios from "axios";
import dayjs from "dayjs";
import {
  platforms,
  resourceSearchRespKeyToTitle,
  SINA_RESOURCE_PROXY_URL,
} from "../../constant";
import "./search.less";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SearchComponent = () => {
  const [url, setUrl] = useState("");
  const [radioValue, setRadioValue] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [, setAvgTime] = useState("");
  const [btnText, setBtnText] = useState("搜索");
  const [videoList, setVideoList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [playList, setPlayList] = useState([]);
  const playerActiveUI = useRef({
    all: true,
    playButton: true,
    playList: true,
    prevNnext: true,
    volume: true,
    volumeSlider: true,
    repeatType: false,
    trackTime: true,
    trackInfo: true,
    artwork: true,
    progress: "waveform",
  });

  const handleInputValChange = (url) => {
    const platform = platforms.find((platform) =>
      url.includes(platform.matchWord)
    );
    if (platform) {
      setRadioValue(platform.siteId);
    } else {
      setRadioValue(0);
    }
    setUrl(url);
  };

  const handleSearch = async () => {
    if (!radioValue) {
      message.error({
        type: "error",
        content: `请选择平台`,
      });
      return;
    }
    try {
      setBtnLoading(true);
      setBtnText(`搜索中`);
      setSearchResults([]);
      setVideoList([]);
      setImageList([]);
      setPlayList([]);
      const response = await axios.get(
        `${process.env.REACT_APP_BFF_DOMAIN}/resource-search`,
        {
          params: {
            url: encodeURIComponent(url),
            siteId: radioValue,
          },
        }
      );

      if (response.data.code === 0) {
        if (response.data.data.code === 200) {
          const resp = response.data.data.data[0];
          const listResult = [];
          Object.keys(resourceSearchRespKeyToTitle).forEach((key) => {
            if (
              Object.hasOwnProperty.call(resp, key) &&
              !Array.isArray(resp[key]) &&
              resp[key]
            ) {
              listResult.push({
                title: resourceSearchRespKeyToTitle[key].name,
                desc: resp[key] ? resp[key] : "--",
                type: resourceSearchRespKeyToTitle[key].type,
              });
            }
          });
          setSearchResults(listResult);
          if (resp.videos && resp.videos.length) {
            resp.videos.forEach((video) => {
              if (Number(radioValue) === 10017) {
                video.url = `${video.url}&ifWeb=fromWeb`;
              }
            });
            setVideoList(resp.videos);
          }
          if (
            resp.videos &&
            resp.videos.length &&
            resp.videos[0].imageList &&
            resp.videos[0].imageList.length
          ) {
            resp.videos[0].imageList.forEach((image) => {
              if (Number(radioValue) === 10017) {
                image = `${SINA_RESOURCE_PROXY_URL}${image}`;
              }
            });
            setImageList(resp.videos[0].imageList);
          }
          if (
            resp.videos &&
            resp.videos.length &&
            resp.videos[0].musicUrl &&
            resp.videos[0].musicName
          ) {
            setPlayList([
              {
                img: resp.coverUrl,
                id: 1,
                src: resp.videos[0].musicUrl,
                name: resp.videos[0].musicName,
              },
            ]);
          }
        } else {
          message.error({
            type: "error",
            content: response.data.data.msg,
          });
        }
      } else {
        message.error({
          type: "error",
          content: response.data.message,
        });
      }
    } catch (error) {
      message.error({
        type: "error",
        content: `查询失败: ${error}`,
      });
    } finally {
      setBtnLoading(false);
      setBtnText("搜 索");
    }
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    const platform = platforms.find((p) => p.siteId === e.target.value);
    setAvgTime(platform.acquisitionTime);
  };

  const radios = platforms.map((platform) => {
    return (
      <Radio value={platform.siteId} key={platform.siteId}>
        {platform.name}
      </Radio>
    );
  });

  const videos = videoList
    .filter((video) => video.url !== null)
    .map((item) => {
      return (
        <video
          src={item.url}
          key={item.videoId}
          referrerPolicy="no-referrer"
          controls
        ></video>
      );
    });

  const onDownload = ({ src, type = "image", name = "资源" }) => {
    let tail = ".png";
    switch (type) {
      case "audio":
        tail = ".mp3";
        break;
      default:
        break;
    }
    fetch(src)
      .then((response) => {
        console.log(response, "response");
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}${tail}`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const images = imageList.map((image, index) => {
    return (
      <SwiperSlide>
        <Image
          width={200}
          src={image}
          key={index}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) => (
              <Space size={12} className="toolbar-wrapper">
                <DownloadOutlined onClick={() => onDownload(image)} />
              </Space>
            ),
          }}
        />
      </SwiperSlide>
    );
  });

  const getImage = (url) => {
    return <img src={url} alt={url} />;
  };

  const getListItem = (item) => {
    if (item.type === "time") {
      return (
        <>
          <Typography.Text strong>{item.title}:</Typography.Text>{" "}
          <Typography.Text
            className="clamp-1"
            ellipsis={{ tooltip: item.desc }}
            copyable
          >
            {dayjs(item.desc).format("YYYY-MM-DD HH:mm:ss")}
          </Typography.Text>
        </>
      );
    }
    if (item.type === "url") {
      return (
        <>
          <Typography.Text strong>{item.title}:</Typography.Text>
          <a href={item.desc} target="_blank" rel="noreferrer">
            <Typography.Text
              className="clamp-1"
              copyable
              ellipsis={{ tooltip: item.desc }}
            >
              {item.desc}
            </Typography.Text>
          </a>
        </>
      );
    }

    if (item.type === "image") {
      return (
        <>
          <Typography.Text strong>{item.title}:</Typography.Text>
          <div className="image-info-bar">
            <Popover
              placement="topLeft"
              title={item.title}
              content={() => getImage(item.desc)}
              overlayClassName="popoverContainer"
            >
              <FileImageOutlined className="icon-image" />
            </Popover>
            <a href={item.desc} target="_blank" rel="noreferrer">
              <Typography.Text
                className="clamp-1"
                copyable
                ellipsis={{ tooltip: item.desc }}
              >
                {item.desc}
              </Typography.Text>
            </a>
          </div>
        </>
      );
    }

    return (
      <>
        <Typography.Text strong>{item.title}:</Typography.Text>{" "}
        <Typography.Text
          className="clamp-1"
          ellipsis={{ tooltip: item.desc }}
          copyable
        >
          {item.desc}
        </Typography.Text>
      </>
    );
  };

  return (
    <div className="page-search">
      <div>
        <Row gutter={16} className="panel search-bar">
          <Col span={24}>
            <Row gutter={16} justify="center">
              <Col span={18}>
                <Input
                  className="page-search_input"
                  size="large"
                  placeholder="请输入要搜寻的视频链接"
                  value={url}
                  onChange={(e) => handleInputValChange(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Button
                  className="page-search_btn"
                  block
                  size="large"
                  type="primary"
                  loading={isBtnLoading}
                  onClick={handleSearch}
                >
                  {btnText}
                </Button>
              </Col>
            </Row>
          </Col>

          <Row gutter={16} justify="center" style={{ marginTop: "16px" }}>
            <Col className="panel radio-info" span={24}>
              <Radio.Group value={radioValue} onChange={handleRadioChange}>
                {radios}
              </Radio.Group>
            </Col>
          </Row>
        </Row>

        {searchResults.length ? (
          <Row gutter={16} justify="center" style={{ marginTop: "16px" }}>
            <Col className="panel base-info" span={24}>
              <h2>基本信息</h2>
              <List
                dataSource={searchResults}
                renderItem={(item) => (
                  <List.Item>{getListItem(item)}</List.Item>
                )}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}

        {searchResults.length ? (
          <div>
            {
              <Row gutter={16} justify="center" style={{ marginTop: "16px" }}>
                <Col className="panel audio-info" span={24}>
                  <h2>音频信息</h2>
                  {playList.length ? (
                    <div className="audio-player-bar">
                      <AudioPlayer
                        playList={playList}
                        activeUI={playerActiveUI.current}
                      ></AudioPlayer>
                      <DownloadOutlined
                        className="icon-audio-download"
                        onClick={() =>
                          onDownload({
                            src: playList[0].src,
                            type: "audio",
                            name: playList[0].name,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <p className="no-resource-tip">该资源无音频</p>
                  )}
                </Col>
              </Row>
            }

            {
              <Row gutter={16} justify="center" style={{ marginTop: "16px" }}>
                <Col className="panel video-info" span={24}>
                  <h2>图片列表</h2>
                  {images.length ? (
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      pagination={{ clickable: true }}
                      spaceBetween={50}
                      slidesPerView={3}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {images}
                    </Swiper>
                  ) : (
                    <p className="no-resource-tip">该资源无图片</p>
                  )}
                </Col>
              </Row>
            }

            {
              <Row gutter={16} justify="center" style={{ marginTop: "16px" }}>
                <Col className="panel video-info" span={24}>
                  <h2>视频列表</h2>
                  {videos.length ? (
                    <div>
                      {videos}
                      <Typography.Text className="tip">
                        如需下载，请点击视频播放器右侧按钮下载
                      </Typography.Text>
                    </div>
                  ) : (
                    <p className="no-resource-tip">该资源无视频</p>
                  )}
                </Col>
              </Row>
            }
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
