import "./search.less";
import { useState, useEffect } from "react";
import { DatePicker, Input, Select, Cascader, message } from "antd";
import CommonSearch from "@/components/common-search";
import { useNavigate } from "react-router-dom";
import useCommentSearchStore from "@/store";
import { platformType } from "@/constant/index.js";
import chinaJson from "@/assets/china.json";
import provinceJson from "@/assets/province.json";
import { rangePresets } from "@/constant/index.js";
import cn from "classnames";
const { RangePicker } = DatePicker;

function SearchComment() {
  const [platform, setPlatform] = useState("");
  const [areaType, setAreaType] = useState(null);
  const navigate = useNavigate();
  const { searchParams, updateSearchParams } = useCommentSearchStore();

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      updateSearchParams({
        ...searchParams,
        publishTimeStart: dateStrings[0],
        publishTimeEnd: dateStrings[1],
      });
    } else {
      updateSearchParams({
        ...searchParams,
        publishTimeStart: null,
        publishTimeEnd: null,
      });
    }
  };
  const changeUrl = (e) => {
    updateSearchParams({ ...searchParams, url: e.target.value });
  };
  const getSearch = () => {
    if (!searchParams.publishTimeStart) {
      message.error("请选择日期");
      return;
    }
    navigate("/search-comment-list");
  };
  const changePlatform = (plat) => {
    setPlatform(plat);
    updateSearchParams({ ...searchParams, siteIds: [plat] });
  };
  const onChangeCode = (value) => {
    delete searchParams.ipPlaceCode;
    delete searchParams.districtCodes;
    if (areaType === "ipPlace") {
      const params = { ...searchParams, ipPlaceCode: value[0] || "" };
      delete params.districtCodes;
      updateSearchParams(params);
    } else if (areaType === "districtPlace") {
      const params = { ...searchParams, districtCodes: value || [] };
      delete params.ipPlaceCode;
      updateSearchParams(params);
    } else {
      const params = { ...searchParams };
      updateSearchParams(params);
    }
  };

  useEffect(() => {
    searchParams.authors = null;
    searchParams.contentAndMatch = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.type]);

  return (
    <>
      <div className="search-comment-container">
        <div className="inner-wrap">
          <CommonSearch search={getSearch} />
          <div className={cn("search-form", { hasArea: !!areaType })}>
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              className="form-item"
              showTime={{ format: "HH:mm:ss" }}
              format="YYYY-MM-DD HH:mm:ss"
            />
            <Select
              placeholder="请选择属地筛选"
              allowClear
              options={[
                { value: "ipPlace", label: "ip属地" },
                { value: "districtPlace", label: "用户注册地" },
              ]}
              value={areaType}
              onChange={(val) => setAreaType(val || null)}
              className="form-item"
            />
            {areaType === "ipPlace" ? (
              <Cascader
                options={provinceJson}
                className="form-item"
                placeholder="请选择省"
                onChange={onChangeCode}
                changeOnSelect
              />
            ) : null}
            {areaType === "districtPlace" ? (
              <Cascader
                options={chinaJson}
                className="form-item"
                placeholder="请选择省/市/区"
                onChange={onChangeCode}
                changeOnSelect
              />
            ) : null}

            <Input
              placeholder="请输入视频链接"
              className="form-item"
              onChange={changeUrl}
            />
          </div>
          <div className="platform-wrap">
            <div
              onClick={() => changePlatform("")}
              className={!platform ? "platform active" : "platform"}
            >
              全部
            </div>
            {platformType.map((p) => (
              <div
                onClick={() => changePlatform(p.key)}
                className={platform === p.key ? "platform active" : "platform"}
              >
                {p.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchComment;
