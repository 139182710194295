import { message } from "antd";
import useCommentSearchStore from "@/store";
import axios from "axios";
export function useTableList() {
  const { searchParams, updateSearchParams } = useCommentSearchStore();
  const getTableList = async () => {
    if (!searchParams.publishTimeStart) {
      message.error("请选择发布日期");
      return;
    }
    const params = {
      ...searchParams,
    };
    delete params.listData;
    delete params.loading;
    if (params.siteIds?.length && !params.siteIds[0]) {
      delete params.siteIds;
    }
    if (params.type === "关键词" && searchParams.contentAndMatch) {
      params.contentAndMatch = [searchParams.contentAndMatch];
      delete params.authors;
    } else if (params.type === "账号昵称/ID" && searchParams.authors) {
      params.authors = [searchParams.authors];
      delete params.contentAndMatch;
    } else {
      delete params.authors;
      delete params.contentAndMatch;
    }
    updateSearchParams({ ...searchParams, loading: true });
    axios
      .post(`${process.env.REACT_APP_BFF_DOMAIN}/comment-search`, {
        ...params,
      })
      .then((response) => {
        if (response.data.code === 0 && response.data.data.records) {
          console.log(response.data.data, "<----response.data.data ");
          updateSearchParams({
            ...searchParams,
            listData: { ...response.data.data },
            pageSize: response.data.pageSize,
            pageNum: response.data.pageNum,
            total: response.data.total,
            loading: false,
          });
        } else {
          updateSearchParams({
            ...searchParams,
            listData: { records: [] },
            pageSize: response.data.pageSize,
            pageNum: response.data.pageNum,
            total: response.data.total,
            loading: false,
          });
        }
      })
      .catch(() => {
        updateSearchParams({ ...searchParams, loading: false });
      });
  };
  return { getTableList };
}
