import "./index.less";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
function Header() {
  const [activeTab, setActiveTab] = useState("search");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("key");
  useEffect(() => {
    // 当路由变化时执行的逻辑
    console.log("当前路由路径:", location.pathname);
    if (location.pathname.indexOf("search-comment") > -1) {
      setActiveTab("comment");
    } else {
      setActiveTab("search");
    }
  }, [location]);
  return paramValue === "true" ? (
    ""
  ) : (
    <div className="header">
      <div className="left-area">
        <img src="http://www.people-ai.net/img/logo.66a81743.svg" alt="" />
        <div className="menu-wrap">
          <div
            className={
              activeTab === "search" ? "menu-item active" : "menu-item"
            }
            onClick={() => setActiveTab("search")}
          >
            <Link to="/">视频下载</Link>
          </div>
          <div
            className={
              activeTab === "comment" ? "menu-item active" : "menu-item"
            }
            onClick={() => setActiveTab("comment")}
          >
            <Link to="/search-comment">评论检索</Link>
          </div>
        </div>
      </div>
      <h2>资源信息获取工具</h2>
    </div>
  );
}

export default Header;
