import dayjs from "dayjs";
export const platformType = [
  {
    label: "抖音",
    key: "10000",
    icon: "douyin",
  },
  {
    label: "快手",
    key: "10025",
    icon: "kuaishou",
  },
  {
    label: "西瓜",
    key: "10001",
    icon: "xigua",
  },
  {
    label: "微博",
    key: "10017",
    icon: "weibo",
  },
  {
    label: "哔哩哔哩",
    key: "10103",
    icon: "bilibili",
  },
  {
    label: "小红书",
    key: "10029",
    icon: "redbook",
  },
  {
    label: "今日头条",
    key: "10024",
    icon: "toutiao",
  },
];

export const rangePresets = [
  {
    label: "今天",
    value: [
      dayjs(dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss")),
      dayjs(dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss")),
    ],
  },
  {
    label: "昨天",
    value: [
      dayjs(
        dayjs().subtract(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss")
      ),
      dayjs(
        dayjs().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss")
      ),
    ],
  },
  {
    label: "最近7天",
    value: [
      dayjs(
        dayjs().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss")
      ),
      dayjs(dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss")),
    ],
  },
];
