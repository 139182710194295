import { Input } from "antd";
import classnames from "classnames";
import "./index.less";
import useCommentSearchStore from "@/store";
function CommonSearch({ search, customSty }) {
  const { searchParams, updateSearchParams } = useCommentSearchStore();
  const onPressEnter = () => {
    search?.();
  };
  const changeInputText = (e) => {
    const params = { ...searchParams };
    if (params.type === "关键词") {
      params.contentAndMatch = e.target.value;
      searchParams.contentAndMatch = e.target.value;
      delete params.authors;
    } else {
      params.authors = e.target.value;
      searchParams.authors = e.target.value;
      delete params.contentAndMatch;
    }
    updateSearchParams({
      ...params,
    });
  };

  const changeType = (type) => {
    const params = { ...searchParams };
    delete params.authors;
    delete params.contentAndMatch;
    updateSearchParams({ ...params, type });
  };
  return (
    <>
      <div className={classnames({ "search-type": true })}>
        <div
          className={
            searchParams.type === "账号昵称/ID"
              ? "search-type-item active"
              : "search-type-item "
          }
          onClick={() => changeType("账号昵称/ID")}
        >
          账号昵称/ID
        </div>
        <div
          className={
            searchParams.type === "关键词"
              ? "search-type-item active"
              : "search-type-item "
          }
          onClick={() => changeType("关键词")}
        >
          关键词
        </div>
      </div>
      <div className={classnames("search-comment-input", customSty)}>
        <Input
          placeholder="请输入要查询的账号昵称或ID"
          onPressEnter={onPressEnter}
          value={searchParams.contentAndMatch || searchParams.authors}
          onChange={changeInputText}
        />
        <div className="search-button" onClick={onPressEnter}>
          <img alt="search" src="/search-icon.png" />
        </div>
      </div>
    </>
  );
}

export default CommonSearch;
