import "./index.less";
import CommonSearch from "@/components/common-search";
import Filter from "./components/filter";
import Header from "@/components/appHeader";
import Table from "./components/table";
import { useTableList } from "@/hook";
import { Spin } from "antd";
import useCommentSearchStore from "@/store";
function CommonSearchList() {
  const { searchParams } = useCommentSearchStore();
  const { getTableList } = useTableList();
  const getSearch = async () => {
    getTableList();
  };

  return (
    <>
      {searchParams.loading ? <Spin fullscreen size="large" /> : null}
      <Header />
      <div className="comment-search-list-wrap">
        <div className="input-box">
          <div className="list-input-wrap">
            <CommonSearch search={getSearch} customSty="list-input" />
          </div>
        </div>
        <Filter />
        <Table />
      </div>
    </>
  );
}

export default CommonSearchList;
