export const platforms = [
  {
    siteId: 10000,
    name: "抖音",
    estimatedMarket: "99%",
    acquisitionTime: "5.8秒",
    key: "douyin",
    matchWord: "www.douyin.com",
  },
  {
    siteId: 10025,
    name: "快手",
    estimatedMarket: "99%",
    acquisitionTime: "18秒",
    key: "kuaiShou",
    matchWord: "www.kuaishou.com",
  },
  {
    siteId: 10017,
    name: "微博",
    estimatedMarket: "99%",
    acquisitionTime: "4.7秒",
    key: "weiBo",
    matchWord: "weibo.com",
  },
  {
    siteId: 10103,
    name: "哔哩哔哩",
    estimatedMarket: "99%",
    acquisitionTime: "2.4秒",
    key: "biLiBiLi",
    matchWord: "www.bilibili.com/video",
  },
  {
    siteId: 10001,
    name: "西瓜",
    estimatedMarket: "99%",
    acquisitionTime: "17秒",
    key: "xiGua",
    matchWord: "www.ixigua.com/",
  },
  {
    siteId: 10012,
    name: "好看",
    estimatedMarket: "99%",
    acquisitionTime: "6.5秒",
    key: "haoKan",
    matchWord: "haokan.baidu.com/",
  },
  {
    siteId: 10011,
    name: "优酷",
    estimatedMarket: "99%",
    acquisitionTime: "17秒",
    key: "youKu",
    matchWord: "v.youku.com",
  },
  {
    siteId: 10010,
    name: "腾讯",
    estimatedMarket: "99%",
    acquisitionTime: "39秒",
    key: "tengXun",
    matchWord: "v.qq.com",
  },
  {
    siteId: 10030,
    name: "头条",
    estimatedMarket: "99%",
    acquisitionTime: "7秒",
    key: "touTiao",
    matchWord: "www.toutiao.com",
  },
];

export const resourceSearchRespKeyToTitle = {
  siteName: { name: "站点名称", type: "text" },
  siteId: { name: "站点ID", type: "text" },
  dataId: { name: "数据ID", type: "text" },
  secUid: { name: "secUid", type: "text" },
  siteAuthorId: { name: "作者站点ID", type: "text" },
  authorId: { name: "作者ID", type: "text" },
  authorName: { name: "作者名称", type: "text" },
  avatarUrl: { name: "头像URL", type: "image" },
  coverUrl: { name: "封面图片URL", type: "image" },
  content: { name: "内容", type: "text" },
  title: { name: "标题", type: "text" },
  likeNum: { name: "点赞数", type: "text" },
  readNum: { name: "阅读数", type: "text" },
  repeatNum: { name: "转发数", type: "text" },
  commentNum: { name: "评论数", type: "text" },
  topics: { name: "话题", type: "text" },
  awemeId: { name: "抖音作品ID", type: "text" },
  webUrl: { name: "资源URL", type: "url" },
  publishTime: { name: "发布时间", type: "time" },
  crawlTime: { name: "抓取时间", type: "time" },
  comments: { name: "评论", type: "text" },
  channelInfo: { name: "频道信息", type: "text" },
  poiInfo: { name: "POI信息", type: "text" },
  articleUrl: { name: "文章URL", type: "url" },
  postUrl: { name: "帖子URL", type: "text" },
  postedUrl: { name: "发布URL", type: "text" },
  videos: { name: "视频", type: "text" },
  duration: { name: "视频时长", type: "text" },
  url: { name: "视频URL", type: "url" },
  videoId: { name: "视频ID", type: "text" },
  musicId: { name: "音乐ID", type: "text" },
  musicName: { name: "音乐名称", type: "text" },
  musicUrl: { name: "音乐URL", type: "text" },
  imageList: { name: "图片列表", type: "text" },
  audios: { name: "音频", type: "text" },
  pics: { name: "图片", type: "text" },
  words: { name: "文字", type: "text" },
};

export const SINA_RESOURCE_PROXY_URL = "http://img.people-ai.net/sina?url=";
