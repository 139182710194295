import "./index.less";
import Header from "@/components/appHeader";
import Search from "./components/search";
import useCommentSearchStore from "@/store";
import { useEffect } from "react";
function SearchComment() {
  const { init } = useCommentSearchStore();
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header></Header>
      <Search />
    </>
  );
}

export default SearchComment;
