import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import zhCN from "antd/es/locale/zh_CN";
import SearchPage from "./resourceSearch";
import SearchComment from "./commentSearch";
import CommonSearchList from "./commentSearchList";
function App() {
  return (
    <div className="App">
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: "#DA2428",
          },
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<SearchPage />} />
            <Route path="/search-comment" element={<SearchComment />} />
            <Route path="/search-comment-list" element={<CommonSearchList />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
