import "./index.less";
import { Input, Select, DatePicker, Cascader } from "antd";
import dayjs from "dayjs";
import useCommentSearchStore from "@/store";
import { useState, useEffect } from "react";
import chinaJson from "@/assets/china.json";
import provinceJson from "@/assets/province.json";
import { rangePresets } from "@/constant/index.js";
const { RangePicker } = DatePicker;

function Filter() {
  const { searchParams, updateSearchParams } = useCommentSearchStore();
  const [pubishTime, setPublishTime] = useState(null);
  const [areaType, setAreaType] = useState(null);
  const onChangeCode = (value) => {
    delete searchParams.ipPlaceCode;
    delete searchParams.districtCodes;
    if (areaType === "ipPlace") {
      const params = { ...searchParams, ipPlaceCode: value[0] || [] };
      delete params.districtCodes;
      updateSearchParams(params);
    } else if (areaType === "districtPlace") {
      const params = { ...searchParams, districtCodes: value || [] };
      delete params.ipPlaceCode;
      updateSearchParams(params);
    } else {
      const params = { ...searchParams };
      updateSearchParams(params);
    }
  };
  const changeUrl = (e) => {
    updateSearchParams({ ...searchParams, url: e.target.value });
  };
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      updateSearchParams({
        ...searchParams,
        publishTimeStart: dateStrings[0],
        publishTimeEnd: dateStrings[1],
      });
    } else {
      updateSearchParams({
        ...searchParams,
        publishTimeStart: null,
        publishTimeEnd: null,
      });
    }
  };
  const changeAreaType = (val) => {
    const params = { ...searchParams };
    delete params.ipPlaceCode;
    delete params.districtCodes;
    setAreaType(val);
    updateSearchParams({ ...params });
  };
  useEffect(() => {
    if (searchParams.publishTimeStart && searchParams.publishTimeEnd) {
      setPublishTime([
        dayjs(searchParams.publishTimeStart),
        dayjs(searchParams.publishTimeEnd),
      ]);
    } else {
      setPublishTime(null);
    }
    if (searchParams.ipPlaceCode?.length) {
      setAreaType("ipPlace");
    } else if (searchParams.districtCodes?.length) {
      setAreaType("districtPlace");
    }
  }, [searchParams]);
  return (
    <div className="filter-box">
      <div className="title">检索条件</div>
      <div className="filter-form">
        <div className="filter-item">
          <div className="filter-label">发布日期</div>
          <RangePicker
            presets={rangePresets}
            style={{ width: 330 }}
            value={pubishTime}
            showTime={{ format: "HH:mm:ss" }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={onRangeChange}
            allowClear={false}
          />
        </div>
        <div className="filter-item">
          <div className="filter-label">属地筛选</div>
          <Select
            style={{ width: 228 }}
            placeholder="请选择属地筛选"
            allowClear
            options={[
              { value: "ipPlace", label: "ip属地" },
              { value: "districtPlace", label: "用户注册地" },
            ]}
            value={areaType}
            onChange={(val) => changeAreaType(val || null)}
          />
        </div>

        {areaType === "ipPlace" ? (
          <div className="filter-item">
            <div className="filter-label">地区选择</div>
            <Cascader
              options={provinceJson}
              className="form-item"
              placeholder="请选择省"
              onChange={onChangeCode}
              changeOnSelect
              value={searchParams.ipPlaceCode}
            />
          </div>
        ) : null}
        {areaType === "districtPlace" ? (
          <div className="filter-item">
            <div className="filter-label">地区选择</div>
            <Cascader
              options={chinaJson}
              className="form-item"
              placeholder="请选择省/市/区"
              changeOnSelect
              onChange={onChangeCode}
              value={searchParams.districtCodes}
            />
          </div>
        ) : null}

        <div className="filter-item">
          <div className="filter-label">视频链接</div>
          <Input
            placeholder="请输入视频链接"
            onChange={changeUrl}
            style={{ width: 500 }}
            value={searchParams.url}
          />
        </div>
      </div>
    </div>
  );
}

export default Filter;
