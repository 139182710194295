import Header from "@/components/appHeader";
import Searcher from "./components/search";
function PageResourceSearch() {
  return (
    <>
      <Header></Header>
      <Searcher></Searcher>
    </>
  );
}

export default PageResourceSearch;
