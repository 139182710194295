import { create } from "zustand";

const useCommentSearchStore = create((set) => ({
  searchParams: {
    type: "账号昵称/ID",
  },
  updateSearchParams: (params) =>
    set((state) => ({ searchParams: { ...params } })),
  init: () =>
    set(() => ({
      searchParams: { pageNum: 1, pageSize: 10, type: "账号昵称/ID" },
    })),
}));

export default useCommentSearchStore;
