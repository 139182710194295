/* eslint-disable jsx-a11y/alt-text */
import "./index.less";
import { Checkbox, Avatar, message, Empty } from "antd";
import { useState, useMemo, useEffect } from "react";
import SortArrow from "@/components/sortArrow";
import { CopyOutlined, LikeOutlined } from "@ant-design/icons";
import cn from "classnames";
import { platformType } from "@/constant/index.js";
import { cloneDeep } from "lodash";
import useCommentSearchStore from "@/store";
import { useTableList } from "@/hook";
import { CopyToClipboard } from "react-copy-to-clipboard";
function Table() {
  const [messageApi, contextHolder] = message.useMessage();
  const { searchParams, updateSearchParams } = useCommentSearchStore();
  const [tableList, setTableList] = useState({ records: [] });
  const { getTableList } = useTableList();
  const changePlatform = (plat) => {
    const params = { ...searchParams, siteIds: [plat] };
    if (!plat) {
      delete params.siteIds;
    }
    updateSearchParams(params);
  };

  const indeterminate = useMemo(() => {
    return (
      !tableList.records.every((list) => list.checked) &&
      tableList.records.some((list) => list.checked)
    );
  }, [tableList]);

  const isCheckAll = useMemo(() => {
    if (!tableList.records || !tableList.records.length) return false;
    return tableList.records.every((list) => list.checked);
  }, [tableList]);

  const checkItem = (type) => {
    const params = { ...searchParams };
    if (type === "default") {
      delete params.likeNumSort;
      delete params.publishTimeSort;
    } else if (type === "publishTimeSort") {
      params.publishTimeSort =
        params.publishTimeSort === "DESC" ? "ASC" : "DESC";
      delete params.likeNumSort;
    } else if (type === "likeNumSort") {
      params.likeNumSort = params.likeNumSort === "DESC" ? "ASC" : "DESC";
      delete params.publishTimeSort;
    }
    updateSearchParams(params);
  };
  const checkAll = (e) => {
    const listData = cloneDeep(tableList.records);
    listData.forEach((item) => {
      item.checked = e.target.checked;
    });
    setTableList((state) => ({ ...state, records: listData }));
  };

  const changeItemCheck = (e, id) => {
    const listData = cloneDeep(tableList.records);
    const currentItem = listData.find((list) => list.id === id);
    currentItem.checked = e.target.checked;
    setTableList((state) => ({ ...state, records: listData }));
  };

  const handleCopy = () => {
    messageApi.success("复制成功");
  };

  useEffect(() => {
    if (searchParams.listData && searchParams.listData?.records?.length) {
      setTableList(searchParams.listData);
    } else {
      setTableList({ records: [] });
    }
  }, [searchParams.listData]);

  useEffect(() => {
    if (!searchParams.publishTimeStart) return;
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.publishTimeSort,
    searchParams.likeNumSort,
    searchParams.siteIds,
  ]);

  return (
    <div className="table-box">
      {contextHolder}
      <div className="select-box">
        <div className="top-box">
          <div className="check-all">
            <Checkbox
              checked={isCheckAll}
              indeterminate={indeterminate}
              onChange={checkAll}
            >
              全选
            </Checkbox>
          </div>
          <div className="select-wrap">
            <div
              className={cn("select-item", {
                active:
                  !searchParams.publishTimeSort && !searchParams.likeNumSort,
              })}
              onClick={() => checkItem("default")}
            >
              默认
            </div>
            <div
              className={cn("select-item", {
                active: !!searchParams.publishTimeSort,
              })}
              onClick={() => checkItem("publishTimeSort")}
            >
              发布时间
              <SortArrow status={searchParams.publishTimeSort} />
            </div>
            <div
              className={cn("select-item", {
                active: !!searchParams.likeNumSort,
              })}
              onClick={() => checkItem("likeNumSort")}
            >
              点赞数
              <SortArrow status={searchParams.likeNumSort} />
            </div>
          </div>
        </div>
        <div className="bottom-box">
          <div className="label">来源平台:</div>
          <div className="platform-wrap">
            <div
              className={cn("platform", {
                active:
                  !searchParams.siteIds || searchParams?.siteIds?.includes(""),
              })}
              onClick={() => changePlatform("")}
            >
              全部
            </div>
            {platformType.map((platform) => (
              <div
                key={platform.label}
                className={cn("platform", {
                  active: searchParams?.siteIds?.includes(platform.key),
                })}
                onClick={() => changePlatform(platform.key)}
              >
                <img
                  className="platform-icon"
                  src={`/images/${platform.icon}.svg`}
                />
                {platform.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      {tableList.records?.length ? (
        <div className="table-list">
          {tableList.records.map((record) => (
            <div className="list-item" key={record.id}>
              <div className="list-item-top">
                <div className="avatar-wrap">
                  <div>
                    <Checkbox
                      checked={record.checked}
                      onChange={(e) => changeItemCheck(e, record.id)}
                    ></Checkbox>
                  </div>
                  <div className="avatar-box">
                    <Avatar size={40} src={record.commentAvatarUrl} />
                    <div className="user-info">
                      <div className="nickname">{record.commentAuthorName}</div>
                      <div className="location">
                        <div className="ip-location">
                          IP属地：{record.commentIpLocation}
                        </div>
                        <div className="divider"></div>
                        <div className="register-location">
                          用户注册地：{record.commentDistrict}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-box">
                  <div className="content">{record.content}</div>
                  <CopyToClipboard text={record.content} onCopy={handleCopy}>
                    <div className="copy-text">
                      <CopyOutlined />
                      <span>复制</span>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className="sorce-info">
                  <div className="source-content">
                    <div className="source-text">原文内容：{record.title}</div>
                    <div className="check-source">
                      <a target="_blank" href={record.url} rel="noreferrer">
                        查看原文
                      </a>
                    </div>
                  </div>
                  {/* <div className="author-info">
                  <div className="author-text">爱环境的作者</div>
                  <div className="publishtime">2023-08-06 23：23：34</div>
                </div> */}
                </div>
                <div className="publish-info">
                  <div className="left-box">
                    <div className="publish-platform">{record.siteName}</div>
                    <div className="publish-time">{record.publishTime}</div>
                  </div>
                  <div>
                    <LikeOutlined />
                    <span className="icon-num">{record.likeNum}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-data">
          <Empty />
        </div>
      )}

      {/* <div className="table-pagination">
        <Pagination
          total={tableList.total || 0}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `共 ${total} 条`}
        />
      </div> */}
    </div>
  );
}

export default Table;
